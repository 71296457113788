import { Component, HostListener, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { isPlaying } from '../../../core/redux/reducers/player.reducer';

@Component({
    selector: 'waveform',
    templateUrl: './waveform.component.html',
    styleUrls: ['./waveform.component.scss'],
    standalone: false
})
/**
 * Dummy implementation of wavefrom using images
 */
export class WaveformComponent implements OnInit {
  isPlaying1$ = this.store.select(isPlaying(1));
  isPlaying2$ = this.store.select(isPlaying(2));

  dndStartX = 0;
  waveformOffset = 'translateX(0)';

  constructor(private store: Store) {}

  ngOnInit() {}

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    this.dndStartX = event.screenX;
  }

  @HostListener('mouseup', ['$event'])
  mouseUp(event: MouseEvent) {
    this.dndStartX = null;
  }

  @HostListener('drag', ['$event'])
  mouseDrag(event: MouseEvent) {
    const diffX = event.screenX - this.dndStartX;
    this.waveformOffset = `translateX(${diffX}px)`;
  }

  @HostListener('mousemove', ['$event'])
  mouseMove(event: MouseEvent) {
    if (this.dndStartX) {
      const diffX = event.screenX - this.dndStartX;
      this.waveformOffset = `translateX(${diffX}px)`;
    }
  }
}
