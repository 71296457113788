export class Effects {
  constructor() {
    this.resonance = new Effect();
    this.cutoff = new Effect();
    this.flanger = new Flanger();
    this.eqHigh = new Effect();
    this.eqMid = new Effect();
    this.eqLow = new Effect();
  }
}
export class Effect {
  constructor() {
    this.active = false;
    this.value = 0;
  }
}
export class Flanger extends Effect {
  constructor() {
    super();
    this.depth = 0;
    this.rate = 0;
    this.mix = 0;
  }
}
