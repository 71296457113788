// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  firebase: {
    projectId: 'seriennummeraktivierung-beb6e',
    appId: '1:445783780044:web:8488def463f6efc7087069',
    storageBucket: 'seriennummeraktivierung-beb6e.appspot.com',
    apiKey: 'AIzaSyARXsBJIJDtqSlYrWd5mWuO_OvqcqxssZk',
    authDomain: 'seriennummeraktivierung-beb6e.firebaseapp.com',
    messagingSenderId: '445783780044',
  },
  production: false,
  debugDualWaveform: false,
  config : {
    apiKey: "AIzaSyARXsBJIJDtqSlYrWd5mWuO_OvqcqxssZk",
    authDomain: "seriennummeraktivierung-beb6e.firebaseapp.com",
    projectId: "seriennummeraktivierung-beb6e",
    storageBucket: "seriennummeraktivierung-beb6e.appspot.com",
    messagingSenderId: "445783780044",
    appId: "1:445783780044:web:8488def463f6efc7087069"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
