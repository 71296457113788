import { catchError, from, map, of } from 'rxjs';
import { UMSound } from '../index';
import { Player } from './Player';
import { numberToUMSoundResult } from './UMSoundResult';
export class Sound {
  constructor(address) {
    this.address = address;
  }
  getAddress() {
    return this.address;
  }
  createPlayer$() {
    return from(UMSound.UMSound_createPlayer({
      umSoundAddress: this.address
    })).pipe(map(result => {
      return new Player(result.address);
    }), catchError(error => {
      console.error('Failed to create player:', error);
      return of(null);
    }));
  }
  init$(audioType) {
    return from(UMSound.UMSound_init({
      address: this.address,
      audioType: audioType
    })).pipe(map(result => {
      return numberToUMSoundResult(result.umSoundResult);
    }));
  }
}
