import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { InformationTiles } from '../../core/interfaces/information-tiles.interface';

@Component({
    selector: 'app-information-tiles',
    templateUrl: './information-tiles.component.html',
    styleUrls: ['./information-tiles.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class InformationTilesComponent implements OnInit {
  @Input() informationTiles: InformationTiles = {
    playedTracks: { text: 'gespielte Tracks', count: 3432 },
    countOfDjs: { text: 'UltraMixer Djs', count: 4432 },
    countOfCountries: { text: 'Länder', count: 12 },
  };

  constructor() { }

  ngOnInit(): void { }
}
