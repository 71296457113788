import { Component, OnInit } from '@angular/core';
import { WaveformCache } from '../WaveformCache';
import { WaveformCacheI } from '../WaveformCacheI';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-waveform-implementation-test',
    templateUrl: './waveform-implementation-test.component.html',
    styleUrls: ['./waveform-implementation-test.component.scss'],
    standalone: false
})

/**
 * ROUTE: "/waveform/test"
 *
 * Test component displaying different waveform implementations
 * @see BSWaveformComponent
 * @see DualWaveformComponentv1
 * @see DualWaveformComponent
 */
export class WaveformImplementationTestComponent implements OnInit {
  constructor(http: HttpClient) {
    this.http = http;
  }
  http: HttpClient;

  // input value for bs-waveform component
  cacheData: WaveformCache;

  ngOnInit() {
    this.loadWaveformCache();
  }

  loadWaveformCache() {
    /*this.http
      .get("./assets/testWaveformCache/data1.json")
      .subscribe((data) => {
        console.log("done loading waveform cache for bs waveform");
        this.cacheData = data; //todo: wrap this with Waveform-Cache class/interface
      });*/

    // .subscribe(data => (this.cacheInfo = data));*/

    this.http.get('./assets/testWaveformCache/data1.json').subscribe((data: WaveformCacheI) => {
      this.cacheData = new WaveformCache(data);
    });
  }
}
