import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  imports: [CommonModule],
  selector: 'um-button',
  templateUrl: './umbutton.component.html',
  styleUrls: ['./umbutton.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UMButtonComponent implements OnInit {
  @Output('selected') _isSelected: EventEmitter<boolean> = new EventEmitter();
  @Input('selected') selected: boolean = false;
  @Input('disabled') disabled: boolean = false;
  @Input('toggleButton') toggleButton: boolean = false;

  constructor() {}

  ngOnInit() {}

  setSelected(selected: boolean) {
    this.selected = selected;
    this._isSelected.emit(selected);
  }

  isSelected() {
    return this.selected;
  }

  handleClick() {
    if (this.toggleButton) {
      this.toggle();
    }
  }

  toggle() {
    this.setSelected(!this.isSelected());
  }
}
